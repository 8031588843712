//  
//  

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import Content from "./pages/content/Content";
import { getApplicationConfig, getUserDetails } from "./api";
import { AppConstants } from "./helpher/util";

initializeIcons();

export default function App() {
    const [Config, setConfig] = useState<any | null>(null);
    const [userDetails, setUserDetails] = useState<any | null>(null);

    async function fetchApplicationConfig() {
        try {
            const v = await getApplicationConfig();
            if (!v) {
                return null;
            }
            setConfig(v);
            document.title = v?.response.title
        } catch (error) {
            // Handle the error here
            console.log(error);
        }
    }

    async function fetchUserDetails() {
        try {
            const v = await getUserDetails();
            if (!v) {
                return null;
            }
            setUserDetails(v);
        } catch (error) {
            // Handle the error here
            console.log(error);
        }
    }
    
 const  getCookies = function (name="")  {
    const regex = new RegExp(`(^| )${name}=([^;]+)`)
    const match = document.cookie.match(regex)
    if (match) {
      return match[2]
    }
}

    useEffect(() => {   
       let x = getCookies('XFTCH-TOKEN') || "";

       if(x){
            fetchUserDetails();
            fetchApplicationConfig();
       }else{
            window.location.href = AppConstants.backendAPI_LOC+"/authenticate";
       }

    }, []);

    return (
        <HashRouter>
            <Routes>
                <Route path="/"  element={<Layout title={Config?.response.title} themeColor={Config?.response.top_ribbon_color}/>}>
                    <Route index element={<Chat envConfig={Config?.response} userDetails={userDetails}/>} />
                    <Route path="content" element={<Content />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </HashRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
