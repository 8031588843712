import React, { useState, useEffect } from 'react';
import {
    SpeechConfig,
    SpeechSynthesizer, ResultReason, SpeakerAudioDestination, AudioConfig
} from 'microsoft-cognitiveservices-speech-sdk';
import { Stack, IconButton, TooltipHost } from "@fluentui/react";  
import { Speaker020Regular ,Pause20Regular , Speaker220Regular, ArrowSyncRegular, Play20Regular} from "@fluentui/react-icons";

 
    
 
import styles from "./TextToSpeech.module.css";

interface Props {
    className?: string;
    text: string;
}
interface Player {
    p: SpeakerAudioDestination | undefined;
    muted: boolean;
}

export const TextToSpeech = ({ className, text }: Props) => {   
    const [isAudioStart, setAudioStart] = useState<boolean>(false);
    const [isSynthStart, setSynthStart] = useState<boolean>(false);
    const [isAudioPaused, setAudioPaused] = useState<boolean>(false);
    const [player, updatePlayer] = useState<Player>({p: undefined, muted: false});
    const handleSynthesize = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setSynthStart(true);
        var player = new SpeakerAudioDestination();
        updatePlayer((p) => {            
            p.p = player; 
            return p;
        });
        player.onAudioEnd = function (_) {
            
            setAudioStart(false);
        };
        player.onAudioStart = function (_) {
            setSynthStart(false);
            setAudioStart(true);
        };
        var audioConfig = AudioConfig.fromSpeakerOutput(player);
        const speechConfig = SpeechConfig.fromSubscription('6ab36dd3d0dc49549d225a8280af540d', 'westeurope');
        var synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);
        const complete_cb = function (result: any) {
           
            if (result.reason === ResultReason.SynthesizingAudioCompleted) {
                
            } else if (result.reason === ResultReason.Canceled) {
                console.log("synthesis failed. Error detail: " + result.errorDetails);
            }
            synthesizer.close();
            // synthesizer = undefined;
        };
        const err_cb = function (err: any) {
            window.console.log(err);
            synthesizer.close();
            //synthesizer = undefined;
        };

        synthesizer.synthesisStarted = function (s, e) {
            console.log("Speech started");
        };
        synthesizer.speakTextAsync(text,
            complete_cb,
            err_cb);
    };
    async function handleMute() {
        updatePlayer(p => { 
            if (!p.muted) {
                p?.p?.pause();
                setAudioPaused(true);
                return {p: p.p, muted: true}; 
            } else {
                p?.p?.resume();
                setAudioPaused(false)
                return {p: p.p, muted: false}; 
            }
        });
    }
    return (
        <div className={`${styles.speechInputContainer}`}  >

              
           {isSynthStart &&<ArrowSyncRegular primaryFill={"rgba(12, 12, 12, 12)"} aria-hidden="true" aria-label="Speech" fill="none" /> }
            {!isSynthStart &&  !isAudioStart &&  <span onClick={handleSynthesize} ><Speaker020Regular primaryFill={"rgba(12, 12, 12, 12)"} aria-hidden="true" aria-label="Speech" fill="none" /></span> }
           {isAudioStart && <> 
            { !isAudioPaused && <span onClick={() => handleMute()}> <Pause20Regular primaryFill={"rgba(12, 12, 12, 12)"} aria-hidden="true" aria-label="Speech" fill="none" /> </span> }
            { isAudioPaused &&  <span onClick={() => handleMute()}  ><Play20Regular primaryFill={"rgba(12, 12, 12, 12)"} aria-hidden="true" aria-label="Speech" fill="none" /> </span>}
            <Speaker220Regular primaryFill={"rgba(12, 12, 12, 12)"} aria-hidden="true" aria-label="Speech" fill="none" /> </> } 
        </div>
    );
};

