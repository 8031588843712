import { useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { ITag } from "@fluentui/react/lib/Pickers";
import { FilePicker } from "../../components/filepicker/file-picker";
import { FileStatus } from "../../components/FileStatus/FileStatus";
import { TagPickerInline } from "../../components/TagPicker/TagPicker";
import { FolderPicker } from "../../components/FolderPicker/FolderPicker";

import styles from "./Content.module.css";
import Prompts from "../../components/Prompts/Prompts";

export interface IButtonExampleProps {
    // These are set based on the toggles shown above the examples (not needed in real code)
    disabled?: boolean;
    checked?: boolean;
}

interface Props {
    envConfig?: any;
}

const Content = ({ envConfig }: Props) => {
    const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined);
    const [selectedTags, setSelectedTags] = useState<string[] | undefined>(undefined);

    const onSelectedKeyChanged = (selectedFolder: string[]) => {
        setSelectedKey(selectedFolder[0]);
    };

    const onSelectedTagsChanged = (selectedTags: ITag[]) => {
        setSelectedTags(selectedTags.map(tag => tag.name));
    };

    const handleLinkClick = (item?: PivotItem) => {
        setSelectedKey(undefined);
    };

    console.log('EnvConfig in Content:', envConfig);

    return (
        <div className={styles.contentArea}>
            <Pivot aria-label="Upload Files Section" className={styles.topPivot} onLinkClick={handleLinkClick}>
                <PivotItem headerText="Upload Files" aria-label="Upload Files Tab">
                    <div className={styles.App}>
                        <h2>Upload your files here</h2>
                        <FolderPicker allowFolderCreation={true} onSelectedKeyChange={onSelectedKeyChanged} />
                        <TagPickerInline allowNewTags={true} onSelectedTagsChange={onSelectedTagsChanged} />
                        <FilePicker folderPath={selectedKey || ""} tags={selectedTags || []} />
                    </div>
                </PivotItem>
                <PivotItem headerText="Upload Status" aria-label="Upload Status Tab">
                    <FileStatus className="" />
                </PivotItem>
                <PivotItem headerText="Prompts" aria-label="Change System Prompt">
                    <Prompts className="" prompt={envConfig?.system_prompt} />
                </PivotItem>
            </Pivot>
        </div>
    );
};

export default Content;
