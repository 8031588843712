 

 const  getCookies = function (name="")  {
  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = document.cookie.match(regex)
  if (match) {
    return match[2]
  }
}
export function getHeaders(){
  let x = getCookies('XFTCH-TOKEN') || "";
  const headers = {     
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Origin': 'http://localhost:5173',
  'Authorization': 'Bearer '+x
  }
  return headers;
  
}


export async function fetchApi(base_url:string, params:any){
  const headers = getHeaders();
  const response = await fetch(`${base_url}`, {
      method: "GET",
      ...params,
      headers});
      
      
  return response;  
} 

export function processError(error:any){
  
  const {code=""} = error;
  if(code.includes("invalid_header")){
    clearCookies();
  }

} 
export function logOutUser(){
  clearCookies();
  window.location.href = "https://login.windows.net/5eab3893-ca04-42f0-905c-002de6161d0b/oauth2/logout?post_logout_redirect_uri="+AppConstants.frontEnd_LOC
}




/* 
export async function fetchApi(base_url:string, params:any){
  const headers = getHeaders();

    await fetch(base_url, {method: "GET",...params,headers}).then((response) => {
     
    if (response.ok) {

      console.log("response----", response);
      return response.json();
    }
    throw new Error('Something went wrong');
  })
  .catch((error) => {
    console.log(error)
  });  
} */
 export function clearCookies(){  
  document.cookie = "XFTCH-TOKEN" +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  window.location.reload();
 }

 export const enum AppConstants {
  userId = "00000000-0000-0000-0000-000000000000",
  backendAPI_LOC = "https://infoasst-web-kudpv.azurewebsites.net",
  frontEnd_LOC = "https://infoasst-web-kudpv.azurewebsites.net",
  backendAPI_LOC_1 = "http://localhost:5000",
  frontEnd_LOC_1 = "http://localhost:5173/",

}