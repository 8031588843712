import { useEffect, useState } from "react";
import styles from "./ChatHistory.module.css";
import { Delete16Regular, StarFilled, Star16Regular } from "@fluentui/react-icons";
import { updateChatHistoryThreadTitle } from "../../api";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@fluentui/react";

interface Props {
    history?: any;
    selectedConversationThread: (conversationThread: string[]) => void;
    id?: any;
    deleteConversationThread: (e: any, id: number) => void;
    updatefavorite: (e: any, id: number, status: boolean) => void;
    isChatHistoryLoaded?:boolean;
}

export const ChatHistory = ({ history, selectedConversationThread, deleteConversationThread,updatefavorite, id, isChatHistoryLoaded }: Props) => {
    const data = history;
    const [activeTitle, setActiveTitle] = useState(id || null);
    const [title, setTitle] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [editedMessage, setEditedMessage] = useState("");

    useEffect(() => {
        setTitle(groupByDate());
    }, [data]);

    const setConversationThread = (data: any) => {
        setIsEditing(false);
        setActiveTitle(data.id);
        setEditedMessage(data.title);
        selectedConversationThread([data?.id]);
    };

    const groupByDate = () => {
        function groupByMonthAndLast7Days(array: any[]) {
            const result: any = {
                Favorites : [],
                Recent: [],
                
            };

            const currentDate = new Date();
            const oneDay = 24 * 60 * 60 * 1000; // Hours*minutes*seconds*milliseconds
            const last7DaysDate = new Date(currentDate.getTime() - 7 * oneDay);

            array?.forEach((obj: { updatedAt: string | number | Date; date: number; is_favorite: boolean}) => {
                const month = new Date(obj.updatedAt).toLocaleString("default", {
                    month: "long"
                });
                if (!result[month]) {
                    result[month] = [];
                }
                result[month].push(obj);                
                // Checking if the date falls within the last 7 days
                if (new Date(obj.updatedAt) >= last7DaysDate) {
                    result.Recent.push(obj);
                    result[month].pop(obj);
                }
                /* block to group the favorites item */
                if(obj['is_favorite']){ 
                    result.Favorites.push(obj);
                    result[month].pop(obj);
                    result.Recent.pop(obj);
                }               
            
            });
            return result;
        }
        const groupedByMonthAndLast7Days = groupByMonthAndLast7Days(data);
        return groupedByMonthAndLast7Days;
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        const response: any = await updateChatHistoryThreadTitle(editedMessage, activeTitle);
        setIsEditing(false);
        return data.map(function (i: any) {
            if (i.id === response.history.id) {
                i["title"] = response.history.title;
            }
        });
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditedMessage(editedMessage);
    };

    return (
        <div className={styles.titleContainer}>
            <div className={styles.header}>Chat History</div>
            {isChatHistoryLoaded && (
                <div>
                    <CircularProgress color="inherit" size={30} sx={{ marginTop: 2, marginRight: 2 }} /> <span>Loading chat history...</span>
                </div>
            )}
            {history.length === 0 && (
                <div>
                    None
                </div>
            )}
            {Object.entries(title).map(function (i: any) {
                if (i[1].length > 0) {
                    return (
                        <>
                            <h6>{i[0]}</h6>
                            {i[1].map(function (j: any) {
                                return (
                                    <div className={j.id === activeTitle ? styles.active : "" + styles.title}>
                                        {isEditing && j.id === activeTitle ? (
                                            <div>
                                                <textarea value={editedMessage} onChange={e => setEditedMessage(e.target.value)} />
                                                <div className={styles.buttonContainer}>
                                                    <IconButton
                                                        style={{ color: "#3C3B49" }}
                                                        iconProps={{ iconName: "Checkmark" }}
                                                        title="Save"
                                                        ariaLabel="Save"
                                                        onClick={() => handleSaveClick()}
                                                    />
                                                    <IconButton
                                                        style={{ color: "#3C3B49" }}
                                                        iconProps={{ iconName: "Cancel" }}
                                                        title="Cancel"
                                                        ariaLabel="Cancel"
                                                        onClick={() => handleCancelClick()}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <span key={j.id} title={j.title} onClick={() => setConversationThread(j)}>
                                                {j.title}
                                            </span>
                                        )}
                                        {j.id === activeTitle && !isEditing && (
                                            <span>

                                                {j.is_favorite && 
                                                <StarFilled
                                                style={{ color: "#3C3B49", verticalAlign: "inherit" }}
                                                onClick={e => {
                                                    updatefavorite(e, j.id, false);
                                                }}
                                            />}
                                                 {!j.is_favorite && 
                                                <Star16Regular
                                                style={{ color: "#3C3B49", verticalAlign: "inherit" }}
                                                onClick={e => {
                                                    updatefavorite(e, j.id, true);
                                                }}
                                            />}
                                                <IconButton
                                                    style={{ color: "#3C3B49" }}
                                                    iconProps={{ iconName: "Edit" }}
                                                    title="Edit the request"
                                                    ariaLabel="Edit the request"
                                                    onClick={() => handleEditClick()}
                                                />
                                                <Delete16Regular
                                                    style={{ color: "#3C3B49", verticalAlign: "inherit" }}
                                                    onClick={e => {
                                                        deleteConversationThread(e, j.id);
                                                    }}
                                                />
                                            </span>
                                        )}
                                    </div>
                                );
                            })}
                        </>
                    );
                }
            })}
        </div>
    );
};
