import React from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import styles from "./Prompts.module.css";

const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75"
};

const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025"
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 1000px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    margin: 0;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

interface Props {
    className?: string;
    prompt?: string;
}

const Prompts = ({ className, prompt }: Props) => {
    console.log("Prompt in SystemPrompt:", prompt);

    return (
        <div className={styles.container}>
            <div className={styles.promptContainer}>
                <Typography variant="body1">System Prompt Prefix:</Typography>
                <TextareaAutosize aria-label="empty textarea" minRows={4} defaultValue={prompt} />
                <div className={styles.buttonContainer}>
                    <Button variant="outlined">Cancel</Button>
                    <Button variant="contained">Update</Button>
                </div>
            </div>
            <div className={styles.promptContainer}>
                <Typography variant="body1">Followup Question Prompt:</Typography>
                <TextareaAutosize aria-label="empty textarea" minRows={4} defaultValue={prompt} />
                <div className={styles.buttonContainer}>
                    <Button variant="outlined">Cancel</Button>
                    <Button variant="contained">Update</Button>
                </div>
            </div>
            <div className={styles.promptContainer}>
                <Typography variant="body1">Query Prompt Few Shots:</Typography>
                <TextareaAutosize aria-label="empty textarea" minRows={4} defaultValue={prompt} />
                <div className={styles.buttonContainer}>
                    <Button variant="outlined">Cancel</Button>
                    <Button variant="contained">Update</Button>
                </div>
            </div>
            <div className={styles.promptContainer}>
                <Typography variant="body1">Query Prompt Template:</Typography>
                <TextareaAutosize aria-label="empty textarea" minRows={4} defaultValue={prompt} />
                <div className={styles.buttonContainer}>
                    <Button variant="outlined">Cancel</Button>
                    <Button variant="contained">Update</Button>
                </div>
            </div>
            <div className={styles.promptContainer}>
                <Typography variant="body1">Response Prompt Few Shots:</Typography>
                <TextareaAutosize aria-label="empty textarea" minRows={4} defaultValue={prompt} />
                <div className={styles.buttonContainer}>
                    <Button variant="outlined">Cancel</Button>
                    <Button variant="contained">Update</Button>
                </div>
            </div>
        </div>
    );
};

export default Prompts;
